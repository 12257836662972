@import url("//spoqa.github.io/spoqa-han-sans/css/SpoqaHanSansNeo.css");

@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  body {
    @apply font-body;
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  p {
    @apply break-words;
  }

  .type-h1 {
    @apply text-2xl leading-tight;
    @apply xxs:text-3xl lg:text-4xl xl:text-5xl;
  }
  .type-h2 {
    @apply text-xl leading-tight;
    @apply xxs:text-2xl lg:text-3xl;
  }
  .type-h3 {
    @apply text-xl leading-tight;
    @apply xxs:text-2xl;
  }
  .type-h4 {
    @apply text-lg font-bold;
    @apply xs:text-lg xl:text-xl;
  }

  .type-p1 {
    @apply text-sm leading-normal;
    @apply xs:text-base;
  }
  .type-p2 {
    @apply text-sm;
    @apply xs:text-xs xl:text-sm;
  }
  .type-p3 {
    @apply text-xs;
  }

  .mobile-hide {
    @apply hidden xs:block;
  }
  .mobile-only {
    @apply xs:hidden;
  }
  .tablet-only {
    @apply md:hidden;
  }

  .input-text {
    @apply mt-1 px-4 py-3 text-sm border bg-grey_light rounded-xl;
    @apply xs:mt-2 xs:px-5 xs:py-4 xs:text-base xs:rounded-20;
  }

  .textarea {
    @apply h-32 mt-2 px-4 py-3 text-sm border bg-grey_light rounded-xl;
    @apply xs:px-5 xs:py-4 xs:text-base xs:rounded-20;
  }
}

@layer components {
  .investor-list {
    @apply grid grid-cols-1 xxxs:grid-cols-2 gap-3 xs:gap-5 md:gap-30;
  }
  .investor-list-item .item-wrapper:hover .image-wrapper img {
    @apply -translate-y-per10;
  }
  .investor-list-item .item-wrapper:hover svg path {
    @apply fill-emerald;
  }
  .investor-list-item .item-wrapper:hover .tw-button-more {
    @apply -translate-y-full opacity-100;
  }

  .tw-content-wrapper {
    @apply w-full max-w-desktop mx-auto px-2.5;
    @apply xxs:px-5 sm:px-10;
  }
  .tw-content-wrapper.full {
    @apply max-w-none;
  }

  .spacing-y {
    @apply py-content_mobile;
    @apply xs:py-content;
  }
  .spacing-y.top-only {
    @apply pb-0;
  }
  .spacing-y.bottom-only {
    @apply pt-0;
  }

  .rounded-box {
    @apply bg-white rounded-2xl;
  }

  .tw-button-wrapper {
    @apply flex w-full mt-5;
    @apply xs:mt-7;
  }

  .tw-button-fill {
    @apply flex justify-center items-center h-button_default_mobile min-h-button_default_mobile px-6 no-underline bg-primary_blue_deep text-sm text-white font-bold rounded-full shadow-button_default;
    @apply transform transition ease-default duration-750;
    @apply hover:bg-primary_blue_deep_hover hover:text-white hover:no-underline hover:-translate-y-2;
    @apply xs:font-normal xs:h-button_default xs:text-lg xs:px-10;
  }
  .tw-button-fill.large {
    @apply h-button_default xs:h-button_large;
  }
  .tw-button-fill.short {
    @apply px-8;
  }
  .tw-button-fill.icon {
    @apply justify-between px-6 xs:px-7;
  }
  .tw-button-fill.icon .icon {
    @apply flex-none w-4 xs:w-6 ml-7;
  }
  .tw-button-fill.black {
    @apply bg-black;
    @apply hover:bg-primary_blue_deep_hover;
  }
  .tw-button-fill.confirm {
    @apply bg-button_confirm shadow-button_confirm;
    @apply hover:bg-green_confirm_hover;
  }
  .tw-button-fill.cancel {
    @apply bg-red_cancel shadow-button_cancel;
    @apply hover:bg-red_cancel_hover;
  }
  .tw-button-fill[disabled] {
    @apply bg-grey_disabled shadow-none text-gray-300 cursor-not-allowed;
    @apply hover:bg-grey_disabled hover:translate-y-0;
  }
}

@layer utilities {
  .leading-title {
    line-height: 1.25 !important;
  }

  .rounded-box-subscribes {
    @apply flex flex-col bg-transparent rounded-none;
    @apply md:overflow-hidden xs:flex-row xs:bg-white xs:rounded-2xl;
  }
  .rounded-box-subscribes .item {
    @apply overflow-hidden w-full mb-5 bg-white rounded-2xl;
    @apply xs:overflow-visible xs:w-1/4 xs:mb-0 xs:bg-transparent xs:rounded-none;
  }
  .rounded-box-subscribes .item:hover .subscribe-type-icon {
    @apply transform scale-110 bg-subscribe_icon_hover;
  }
}
